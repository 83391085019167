<script setup lang="ts">
const { status } = useAuth();
const { getCompanyLogoImage } = useCompanyTerm();

const logoUrl = ref<string>();

watch(
  () => status.value,
  async () => {
    // ログイン状態が変わったら会社ロゴを取得
    if (status.value === 'authenticated') {
      logoUrl.value = await getCompanyLogoImage();
    }

    // ログアウト状態になったらロゴ画像を削除
    if (status.value === 'unauthenticated') {
      logoUrl.value = undefined;
    }
  },
  { immediate: true },
);
</script>

<template>
  <NuxtLink to="/" class="no-underline">
    <header class="bg-primary text-white">
      <div class="container mx-auto py-4">
        <h1 class="text-center text-lg font-bold">
          <template v-if="status === 'unauthenticated'">
            リロクリエイト<br />お知らせ管理サイト</template
          >
          <template v-if="status === 'authenticated' && logoUrl">
            <img class="mx-auto w-32" :src="logoUrl" alt="ロゴ画像" />
          </template>
        </h1>
      </div>
    </header>
  </NuxtLink>
</template>
